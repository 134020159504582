import UiClass from './ui';
import UserClass from './user';
import NavigationClass from './navigation';

/**
 * Add your stores here
 */
export class RootStore {
  ui = new UiClass();
  user = new UserClass();

  navigation = new NavigationClass();
}

export const rootStore = new RootStore();
