import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import { wait } from '../../utils/helpers';
import { MobileBlocker } from '../common/MobileBlocker';
import { StepDivider } from '../common/StepDivider';
import SvgTrash from '../common/svg/SvgTrash';
import { BackgroundImg } from './BackgroundImg';
import { CreationSteps } from './CreationSteps';
import {
  EnhancementsSelection,
  keyOfEnhancement,
} from './EnhancementsSelection';
import { InfoBox } from './InfoBox';
import { Step2Box } from './Step2Box';
import { Step3Box } from './Step3Box';

const HomeScreen = () => {
  const [currentInfo, setCurrentInfo] = useState<1 | 2>(1);
  const [term, setTerm] = useState('');
  const [termIsFocus, setTermIsFocus] = useState(false);
  const [focusEnhancement, setFocusEnhancement] = useState(false);
  const [enhancements, setEnhancements] = useState<keyOfEnhancement[]>([]);
  const [generateState, setGenerateState] = useState<
    'idle' | 'generating' | 'completed' | 'finished'
  >('idle');
  const [generateProgress, setGenerateProgress] = useState(0);
  const [step, setStep] = useState(1);

  const isMobile = useIsMobile();

  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);

  const onGeneratePress = async () => {
    setGenerateProgress(0);
    setGenerateState('generating');

    const interval = setInterval(() => {
      setGenerateProgress((s) => (s += 1));
    }, 2000 / 100);
    await wait(2000);
    clearInterval(interval);

    setGenerateState('completed');
  };

  const onSavePress = async () => {
    // setGenerateState('completed');
    setStep(2);
  };

  const onStep2Save = () => {
    setStep(3);
  };

  useEffect(() => {
    switch (step) {
      case 2:
        step2Ref.current?.scrollIntoView();
        break;
      case 3:
        step3Ref.current?.scrollIntoView();
        break;
    }
  }, [step]);

  useEffect(() => {
    if (currentInfo === 1) {
      setFocusEnhancement(false);
      setEnhancements([]);
    }
  }, [currentInfo]);

  if (isMobile) {
    return <MobileBlocker />;
  }

  return (
    <Box pt={78} pb={100}>
      <Grid
        templateColumns={{ base: '1fr', xl: 'minmax(min-content, 423px) 1fr' }}
        columnGap={24}
      >
        <GridItem>
          <CreationSteps currentStep={step} />

          {step === 1 && <InfoBox currentInfo={currentInfo} />}

          <motion.div>
            <Box
              mt={21}
              border="1px solid rgba(144, 159, 191, 0.2)"
              borderRadius={24}
              p={24}
              bg="white"
            >
              {step === 1 ? (
                <Box>
                  <InputGroup
                    height={60}
                    // px={16}
                    bg={term.length > 0 && !termIsFocus ? '#F6F8FA' : 'white'}
                    _hover={{
                      bg: '#F6F8FA',
                    }}
                    borderRadius={16}
                  >
                    <Input
                      _placeholder={{
                        color: 'lightGrey',
                      }}
                      value={term}
                      onFocus={() => setTermIsFocus(true)}
                      onBlur={() => {
                        setTermIsFocus(false);

                        if (term.length > 0) {
                          setCurrentInfo(2);
                        }
                      }}
                      onChange={(e) => setTerm(e.target.value)}
                      lineHeight="22px"
                      height={60}
                      borderRadius={16}
                      pl={16}
                      pr={52}
                      focusBorderColor="purple"
                      placeholder="Try typing “sandy beach and blue skies”"
                    />
                    <AnimatePresence>
                      {currentInfo === 2 && !termIsFocus && (
                        <motion.div
                          layout
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.2 }}
                        >
                          <InputRightElement
                            height={60}
                            borderTopRightRadius={16}
                            borderBottomRightRadius={16}
                          >
                            <IconButton
                              height={52}
                              width={52}
                              aria-label="delete"
                              bg="transparent"
                              _hover={{
                                bg: 'transparent',
                              }}
                              _focus={{
                                bg: 'transparent',
                              }}
                              onClick={() => {
                                setTerm('');
                                setCurrentInfo(1);
                              }}
                            >
                              <SvgTrash color="darkGrey" />
                            </IconButton>
                          </InputRightElement>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </InputGroup>

                  <Divider my={24} borderColor="#8084941A" />

                  <EnhancementsSelection
                    onSavePress={onSavePress}
                    onGeneratePress={onGeneratePress}
                    enhancements={enhancements}
                    setEnhancements={setEnhancements}
                    currentInfo={currentInfo}
                    generateState={generateState}
                    focusEnhancement={focusEnhancement}
                    setFocusEnhancement={setFocusEnhancement}
                  />
                </Box>
              ) : (
                <Box>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text mr={8} color="darkGrey" fontWeight={600}>
                      Background
                    </Text>
                    <Text fontWeight={400} color="lightGrey" fontSize={14}>
                      {enhancements.length} enhancements
                    </Text>
                  </Flex>

                  <Box height={1} my={20} width="100%" bg="#8084941A" />

                  <Flex>
                    <Box
                      bg="lighterGrey"
                      mr={12}
                      height={48}
                      width={48}
                      borderRadius={16}
                    />
                    <Flex flex={1}>
                      <Text fontWeight={400} lineHeight={1.5}>
                        A floating sphere in a retro style with a gradient
                        overlay
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Box>
          </motion.div>

          <StepDivider />

          <Step2Box
            onSavePress={onStep2Save}
            currentStep={step}
            boxRef={step2Ref}
          />

          <StepDivider />

          <Step3Box currentStep={step} boxRef={step3Ref} />
        </GridItem>

        <GridItem mt={{ base: 24, xl: 0 }}>
          <BackgroundImg
            currentStep={step}
            generateProgress={generateProgress}
            enhancements={enhancements}
            enhanceOn={currentInfo === 2}
            term={term}
            state={generateState}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default HomeScreen;
