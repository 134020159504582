import { makeAutoObservable } from 'mobx';
import { useStores } from './useStores';

export interface NavigationStoreType {
  navigate?: (route: string) => void;
  setNavigate: (fn: NavigationStoreType['navigate']) => void;
}

/* Used to access react router 6 navigation hook outside of function components */
class NavigationStore {
  navigate: NavigationStoreType['navigate'] = undefined;

  setNavigate: NavigationStoreType['setNavigate'] = (fn) => {
    this.navigate = fn;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default NavigationStore;

export function useNavigationStore(): NavigationStoreType {
  const { navigation } = useStores();
  return navigation as NavigationStoreType;
}
