export const colors = {
  primary: '#70c85c',
  secondary: '#D4B39A',
  white: '#FFFFFF',
  background: '#fafafa',
  black: '#333333',
  error: '#f76565',
  borderColor: '#EFEFEF',
  darkGrey: '#0F2830',
  lightGrey: '#808494',
  lighterGrey: '#F6F8FA',
  purple: '#5943BE',
  blue: '#324367',
};

export default colors;
