import React from 'react';
import { Button as CButton, ButtonProps } from '@chakra-ui/react';

export default function Button(props: ButtonProps) {
  return (
    <CButton
      height={52}
      borderRadius={16}
      bg="blue"
      width="100%"
      textColor="white"
      fontFamily="biennale"
      fontWeight={600}
      fontSize={14}
      lineHeight="20px"
      _hover={{
        bg: 'purple',
      }}
      {...props}
    >
      {props.children}
    </CButton>
  );
}
