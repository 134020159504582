import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default function SvgReset(props: IconProps) {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <g clipPath="url(#clip0_503_6369)">
        <path
          d="M0.87418 10C1.078 9.9974 1.27546 10.071 1.42789 10.2063C1.58033 10.3416 1.67677 10.529 1.69835 10.7317C1.86205 12.6265 2.66843 14.4085 3.98381 15.7823C5.29918 17.156 7.04454 18.0389 8.93052 18.2847C10.8165 18.5304 12.7298 18.1242 14.3533 17.1334C15.9768 16.1426 17.2129 14.6268 17.8567 12.8371C18.5005 11.0475 18.5133 9.09153 17.893 7.29359C17.2728 5.49565 16.0567 3.96369 14.4463 2.95168C12.836 1.93968 10.9282 1.50842 9.03913 1.7294C7.15009 1.95037 5.3933 2.81032 4.06001 4.16667H6.35335C6.57436 4.16667 6.78632 4.25446 6.9426 4.41074C7.09888 4.56702 7.18668 4.77899 7.18668 5C7.18668 5.22101 7.09888 5.43297 6.9426 5.58926C6.78632 5.74554 6.57436 5.83333 6.35335 5.83333H3.02001C2.57799 5.83333 2.15406 5.65774 1.8415 5.34518C1.52894 5.03262 1.35335 4.60869 1.35335 4.16667V0.833333C1.35335 0.61232 1.44114 0.400358 1.59742 0.244078C1.7537 0.0877974 1.96567 0 2.18668 0C2.40769 0 2.61965 0.0877974 2.77593 0.244078C2.93221 0.400358 3.02001 0.61232 3.02001 0.833333V2.84583C4.64421 1.26337 6.75824 0.280237 9.01495 0.0578881C11.2717 -0.16446 13.5369 0.38719 15.4387 1.62225C17.3405 2.85731 18.7658 4.70236 19.4805 6.85442C20.1953 9.00648 20.157 11.3376 19.3719 13.465C18.5868 15.5924 17.1017 17.3897 15.1603 18.5616C13.219 19.7335 10.9368 20.2104 8.68862 19.914C6.44043 19.6176 4.35985 18.5655 2.78853 16.9305C1.21722 15.2956 0.248577 13.1749 0.0416794 10.9167C0.03092 10.8007 0.0444241 10.6837 0.0813313 10.5733C0.118237 10.4628 0.177738 10.3612 0.256046 10.275C0.334352 10.1888 0.429752 10.1198 0.536173 10.0724C0.642593 10.0251 0.757704 10.0004 0.87418 10Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_503_6369">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="matrix(-1 0 0 1 20 0)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
