import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import animationJson from './lotties/bubble-gum-workflow.json';

export const MobileBlocker = () => {
  return (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      maxHeight="100vh"
      height="calc(100vh - 53px - 24px)"
      pb={40}
    >
      <Flex alignItems="center" justifyContent="center" flex={1}>
        <Lottie animationData={animationJson} loop />
      </Flex>
      <Box
        bg="white"
        border="1px solid rgba(144, 159, 191, 0.2)"
        borderRadius={24}
        p={24}
      >
        <Text color="purple" fontWeight={600} lineHeight="22px">
          About us
        </Text>
        <Text fontWeight={400} color="darkGrey" lineHeight={1.5} mt={4}>
          Alvy.ai allows you to create beautiful ad using ai in just 3 simple
          steps. Unfortunately our platform is desktop only.
        </Text>
      </Box>
    </Flex>
  );
};
