import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default function SvgArrowRight(props: IconProps) {
  return (
    <Icon width="20" height="20" viewBox="0 0 20 20" {...props}>
      <path
        d="M10.9342 5.24399C10.778 5.40026 10.6903 5.61219 10.6903 5.83316C10.6903 6.05413 10.778 6.26605 10.9342 6.42232L13.6776 9.16649L5.00008 9.17232C4.77907 9.17232 4.56711 9.26012 4.41083 9.4164C4.25455 9.57268 4.16675 9.78464 4.16675 10.0057C4.16675 10.2267 4.25455 10.4386 4.41083 10.5949C4.56711 10.7512 4.77907 10.839 5.00008 10.839L13.6784 10.8332L10.9342 13.5773C10.8547 13.6542 10.7912 13.7461 10.7475 13.8478C10.7038 13.9495 10.6808 14.0588 10.6799 14.1695C10.6789 14.2801 10.7 14.3899 10.7419 14.4923C10.7838 14.5947 10.8457 14.6877 10.9239 14.766C11.0022 14.8442 11.0952 14.9061 11.1976 14.948C11.3 14.9899 11.4098 15.011 11.5204 15.01C11.6311 15.0091 11.7404 14.9861 11.8421 14.9424C11.9438 14.8987 12.0357 14.8352 12.1126 14.7557L15.1009 11.7682C15.3331 11.536 15.5173 11.2604 15.643 10.957C15.7686 10.6537 15.8333 10.3286 15.8333 10.0002C15.8333 9.6719 15.7686 9.34677 15.643 9.04343C15.5173 8.74009 15.3331 8.46447 15.1009 8.23232L12.1126 5.24399C11.9563 5.08776 11.7444 5 11.5234 5C11.3024 5 11.0905 5.08776 10.9342 5.24399Z"
        fill="currentColor"
      />
    </Icon>
  );
}
