import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default function SvgCheck(props: IconProps) {
  return (
    <Icon width="22" height="23" viewBox="0 0 22 23" {...props}>
      <g clipPath="url(#clip0_218_25675)">
        <path
          d="M17.4167 0.5H4.58333C3.3682 0.501456 2.20326 0.984808 1.34403 1.84403C0.484808 2.70326 0.00145554 3.8682 0 5.08333L0 17.9167C0.00145554 19.1318 0.484808 20.2967 1.34403 21.156C2.20326 22.0152 3.3682 22.4985 4.58333 22.5H17.4167C18.6318 22.4985 19.7967 22.0152 20.656 21.156C21.5152 20.2967 21.9985 19.1318 22 17.9167V5.08333C21.9985 3.8682 21.5152 2.70326 20.656 1.84403C19.7967 0.984808 18.6318 0.501456 17.4167 0.5V0.5ZM18.3333 7.90575L9.8505 16.3886C9.68025 16.5589 9.47811 16.694 9.25563 16.7862C9.03315 16.8784 8.79469 16.9258 8.55387 16.9258C8.31306 16.9258 8.0746 16.8784 7.85212 16.7862C7.62964 16.694 7.4275 16.5589 7.25725 16.3886L3.66667 12.7989C3.49442 12.6267 3.39765 12.3931 3.39765 12.1495C3.39765 11.9059 3.49442 11.6722 3.66667 11.5C3.83891 11.3278 4.07253 11.231 4.31612 11.231C4.55972 11.231 4.79334 11.3278 4.96558 11.5L8.55525 15.0897L17.039 6.60683C17.1243 6.52185 17.2255 6.45449 17.3368 6.40861C17.4481 6.36273 17.5674 6.33923 17.6878 6.33944C17.8082 6.33965 17.9274 6.36358 18.0385 6.40985C18.1497 6.45612 18.2506 6.52384 18.3356 6.60913C18.4206 6.69441 18.488 6.79561 18.5338 6.90692C18.5797 7.01824 18.6032 7.13751 18.603 7.25791C18.6028 7.37831 18.5789 7.4975 18.5326 7.60865C18.4863 7.71981 18.4186 7.82076 18.3333 7.90575Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_218_25675">
          <rect y="0.5" width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
