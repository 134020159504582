import { ChakraProvider } from '@chakra-ui/react';
import * as React from 'react';
import { theme } from '../constants/theme';

import { rootStore } from '../stores';
import Fonts from './common/Fonts';
import Router from './Router';

export const StoreContext = React.createContext<typeof rootStore>(rootStore);

class App extends React.Component {
  render() {
    return (
      <StoreContext.Provider value={rootStore}>
        <ChakraProvider theme={theme}>
          <Fonts />
          <Router />
        </ChakraProvider>
      </StoreContext.Provider>
    );
  }
}

export default App;
