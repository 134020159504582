import { extendTheme } from '@chakra-ui/react';
import colors from './colors';

// Used to prevent chakra from multiplying paddings and margins by 4px
// Before: p={3} === p={'12px'}
// Now: p={3} === p={'3px'}
const themeLengthsProxyHandler = {
  get(_: any, prop: any) {
    if (prop) {
      return `${prop.toString()}px`;
    }
    return '';
  },
};

const themeLengthsProxy = new Proxy({}, themeLengthsProxyHandler);

const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '75em',
};

const fonts = {
  body: 'Biennale',
  heading: 'Biennale',
};

export const theme = extendTheme({
  breakpoints,
  fonts,
  colors,
  space: themeLengthsProxy,
  sizes: themeLengthsProxy,
  components: {
    Progress: {
      baseStyle: {
        filledTrack: {
          bg: colors.purple,
        },
      },
    },
  },
});
