import { observable, action, makeAutoObservable } from 'mobx';
import { useStores } from './useStores';

import metrics from '../constants/metrics';

export interface UIStoreType {
  isMobile: boolean;
  innerHeight: number;
  innerWidth: number;

  updateWindowSizes: ({
    innerHeight,
    innerWidth,
  }: {
    innerWidth: number;
    innerHeight: number;
  }) => void;
}

class UIStore {
  @observable innerHeight = window.innerHeight;
  @observable innerWidth = window.innerWidth;
  @observable isMobile = false;

  @action
  updateWindowSizes({
    innerHeight,
    innerWidth,
  }: {
    innerHeight: number;
    innerWidth: number;
  }) {
    this.innerHeight = innerHeight || this.innerHeight;
    this.innerWidth = innerWidth || this.innerWidth;
    this.isMobile = innerWidth <= metrics.mobileThreshold;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default UIStore;

export function useUiStore(): UIStoreType {
  const { ui } = useStores();
  return ui as UIStoreType;
}
