import { makeAutoObservable } from 'mobx';
import { useStores } from './useStores';

export interface UserStore {}

class UserStr implements UserStore {
  constructor() {
    makeAutoObservable(this);
  }
}

export default UserStr;

export function useUserStore(): UserStore {
  const { user } = useStores();
  return user as UserStore;
}
