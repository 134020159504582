import React, { useEffect } from 'react';
import {
  Box,
  Button as CButton,
  Flex,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import {
  AnimatePresence,
  motion,
  useSpring,
  useTransform,
} from 'framer-motion';
import SvgPlus from '../common/svg/SvgPlus';
import { uniq } from 'lodash';
import Button from '../common/Button';
import SvgReset from '../common/svg/SvgReset';

export const ENHANCEMENTS = {
  style: {
    title: 'Image Style',
    img: '/images/image-style.png',
  },
  color: {
    title: 'Color',
    img: '/images/image-color.png',
  },
  artistStyle: {
    title: 'Artist Style',
    img: '/images/image-artist-style.png',
  },
  lightning: {
    title: 'Lightning',
    img: '/images/image-lightning.png',
  },
  view: {
    title: 'View',
    img: '/images/image-view.png',
  },
  detailLevel: {
    title: 'Detail Level',
    img: '/images/image-detail-level.png',
  },
};

const Enhancement = ({
  name,
  onSelect,
}: {
  name: keyof typeof ENHANCEMENTS;
  onSelect: (name: keyof typeof ENHANCEMENTS) => void;
}) => {
  return (
    <Box
      onClick={() => onSelect(name)}
      cursor="pointer"
      borderRadius={8}
      overflow="hidden"
      position="relative"
      backdropFilter="blur(16px)"
    >
      <img src={ENHANCEMENTS[name].img} />

      <Flex
        alignItems="center"
        justifyContent="center"
        height={32}
        bottom={8}
        left={8}
        right={8}
        position="absolute"
        bg="#0F283099"
        borderRadius={12}
        backdropFilter="blur(2px)"
      >
        <Text
          color="white"
          fontSize={14}
          lineHeight="24px"
          fontWeight={400}
          textAlign="center"
        >
          {ENHANCEMENTS[name].title}
        </Text>
      </Flex>
    </Box>
  );
};

export type keyOfEnhancement = keyof typeof ENHANCEMENTS;

interface Props {
  currentInfo: number;
  focusEnhancement?: boolean;
  setFocusEnhancement: (value: boolean) => void;
  setEnhancements: React.Dispatch<React.SetStateAction<keyOfEnhancement[]>>;
  enhancements: keyOfEnhancement[];
  onGeneratePress: () => void;
  onSavePress: () => void;
  generateState: 'idle' | 'generating' | 'completed' | 'finished';
}

export const EnhancementsSelection = ({
  currentInfo,
  focusEnhancement,
  setFocusEnhancement,
  enhancements,
  setEnhancements,
  onGeneratePress,
  onSavePress,
  generateState,
}: Props) => {
  const x = useSpring(0, { duration: 200 });

  const enhanceTransform = useTransform(x, [0, 1], [100, 0]);
  const enhanceOpacity = useTransform(x, [0, 1], [0, 1]);

  const enhanceStep1Transform = useTransform(x, [0, 1], [0, -100]);
  const enhanceStep1Opacity = useTransform(x, [0, 1], [1, 0]);

  const onSelect = (name: keyOfEnhancement) => {
    setEnhancements((e) => [...uniq([...e, name])]);
    setFocusEnhancement(false);
  };

  useEffect(() => {
    x.set(focusEnhancement ? 1 : 0);
  }, [focusEnhancement]);

  const hasEnhancement = enhancements.length > 0;

  return (
    <>
      <Box
        mt={enhancements.length > 0 ? 8 : 0}
        mb={enhancements.length > 0 ? 14 : 0}
      >
        <AnimatePresence>
          {enhancements.map((e) => (
            <motion.div
              key={e}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              // exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <Flex alignItems="center" mt={12}>
                <Box h={20} mr={10} w={20} borderRadius={10} overflow="hidden">
                  <img src={ENHANCEMENTS[e].img} />
                </Box>
                <Text fontWeight={400} color="darkGrey" lineHeight={1.5}>
                  {ENHANCEMENTS[e].title}
                </Text>
              </Flex>
            </motion.div>
          ))}
        </AnimatePresence>
      </Box>
      <Box height={23}>
        <motion.div
          style={{
            x: enhanceTransform,
            opacity: enhanceOpacity,
            position: 'absolute',
          }}
        >
          <Box>
            <Text color="darkGrey" fontWeight={400} lineHeight="22px">
              SELECT ENHANCEMENT
            </Text>
          </Box>
        </motion.div>
        <motion.div
          style={{
            x: enhanceStep1Transform,
            opacity: enhanceStep1Opacity,
            position: 'absolute',
          }}
        >
          <CButton
            style={{
              display: 'flex',
              backgroundColor: 'transparent',
              height: 22,
            }}
            onClick={() => setFocusEnhancement(true)}
            alignItems="center"
            disabled={currentInfo === 1}
            pl={hasEnhancement ? 0 : 16}
            _disabled={{ opacity: 1 }}
          >
            <SvgPlus color="lightGrey" mr={2} />
            <Text fontWeight={400} lineHeight={1.5} color="lightGrey">
              {hasEnhancement
                ? 'Add Enhancements'
                : 'Enhance Your Background Image'}
            </Text>
          </CButton>
        </motion.div>
      </Box>

      <AnimatePresence>
        {focusEnhancement && (
          <motion.div
            key="enhancements"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
            transition={{
              duration: 0.2,
              type: 'spring',
            }}
          >
            <SimpleGrid spacing={16} columns={3} mt={20}>
              <Enhancement onSelect={onSelect} name="style" />
              <Enhancement onSelect={onSelect} name="color" />
              <Enhancement onSelect={onSelect} name="artistStyle" />
              <Enhancement onSelect={onSelect} name="lightning" />
              <Enhancement onSelect={onSelect} name="view" />
              <Enhancement onSelect={onSelect} name="detailLevel" />
            </SimpleGrid>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {enhancements.length > 0 && (
          <>
            {generateState !== 'completed' ? (
              <motion.div
                key="enhancements_completed"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                // exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {enhancements.length > 0 && (
                  <Button
                    mt={28}
                    disabled={generateState === 'generating'}
                    onClick={onGeneratePress}
                  >
                    Generate Ad Background
                  </Button>
                )}
              </motion.div>
            ) : (
              <motion.div
                key="enhancements_idle"
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                // exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                {enhancements.length > 0 && (
                  <Flex mt={28} width="100%">
                    <CButton
                      role="group"
                      borderColor="#909FBF66"
                      borderWidth={1}
                      borderRadius={16}
                      height={52}
                      width={72}
                      mr={10}
                      bg="white"
                      _hover={{
                        bg: 'white',
                        borderColor: 'purple',
                      }}
                      onClick={onGeneratePress}
                    >
                      <SvgReset
                        _groupHover={{ color: 'purple' }}
                        color="blue"
                      />
                    </CButton>

                    <Flex flex={1}>
                      <Button onClick={onSavePress}>Save and Continue</Button>
                    </Flex>
                  </Flex>
                )}
              </motion.div>
            )}
          </>
        )}
      </AnimatePresence>
    </>
  );
};
