const breakPointsConfig = {
  maxLayoutWidth: 1000,
  mobileThreshold: 1200,
};

export const breakPoints = {
  xs: '320px',
  sm: '375px',
  md: '768px',
  lg: '960px',
  xl: `${breakPointsConfig.mobileThreshold}px`,
  '2xl': '1600px',
  '3xl': '1920px',
};

export default breakPointsConfig;
