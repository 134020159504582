import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  value: number;
  isActive?: boolean;
}

export const StepNumber = ({ value, isActive }: Props) => {
  return (
    <Flex
      bg={isActive ? 'purple' : 'lightGrey'}
      height={22}
      opacity={isActive ? 1 : 0.2}
      width={22}
      borderRadius={11}
      alignItems="center"
      justifyContent="center"
    >
      <Text color="white" fontWeight={600} fontSize={14}>
        {value}
      </Text>
    </Flex>
  );
};
