import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default function SvgTrash(props: IconProps) {
  return (
    <Icon width="20" height="21" viewBox="0 0 20 21" {...props}>
      <g clipPath="url(#clip0_343_3827)">
        <path
          d="M17.5001 3.83333H14.9167C14.7233 2.89284 14.2116 2.04779 13.4678 1.4406C12.724 0.833408 11.7936 0.501212 10.8334 0.5L9.16675 0.5C8.20658 0.501212 7.27618 0.833408 6.53237 1.4406C5.78857 2.04779 5.27683 2.89284 5.08341 3.83333H2.50008C2.27907 3.83333 2.06711 3.92113 1.91083 4.07741C1.75455 4.23369 1.66675 4.44565 1.66675 4.66667C1.66675 4.88768 1.75455 5.09964 1.91083 5.25592C2.06711 5.4122 2.27907 5.5 2.50008 5.5H3.33341V16.3333C3.33474 17.438 3.77415 18.497 4.55526 19.2782C5.33638 20.0593 6.39542 20.4987 7.50008 20.5H12.5001C13.6047 20.4987 14.6638 20.0593 15.4449 19.2782C16.226 18.497 16.6654 17.438 16.6667 16.3333V5.5H17.5001C17.7211 5.5 17.9331 5.4122 18.0893 5.25592C18.2456 5.09964 18.3334 4.88768 18.3334 4.66667C18.3334 4.44565 18.2456 4.23369 18.0893 4.07741C17.9331 3.92113 17.7211 3.83333 17.5001 3.83333ZM9.16675 2.16667H10.8334C11.3503 2.1673 11.8544 2.32781 12.2764 2.6262C12.6985 2.92459 13.0179 3.34624 13.1909 3.83333H6.80925C6.98223 3.34624 7.30167 2.92459 7.72374 2.6262C8.14581 2.32781 8.64985 2.1673 9.16675 2.16667ZM15.0001 16.3333C15.0001 16.9964 14.7367 17.6323 14.2678 18.1011C13.799 18.5699 13.1631 18.8333 12.5001 18.8333H7.50008C6.83704 18.8333 6.20116 18.5699 5.73231 18.1011C5.26347 17.6323 5.00008 16.9964 5.00008 16.3333V5.5H15.0001V16.3333Z"
          fill="currentColor"
        />
        <path
          d="M8.33333 15.5C8.55435 15.5 8.76631 15.4122 8.92259 15.2559C9.07887 15.0996 9.16667 14.8877 9.16667 14.6666V9.66665C9.16667 9.44563 9.07887 9.23367 8.92259 9.07739C8.76631 8.92111 8.55435 8.83331 8.33333 8.83331C8.11232 8.83331 7.90036 8.92111 7.74408 9.07739C7.5878 9.23367 7.5 9.44563 7.5 9.66665V14.6666C7.5 14.8877 7.5878 15.0996 7.74408 15.2559C7.90036 15.4122 8.11232 15.5 8.33333 15.5Z"
          fill="currentColor"
        />
        <path
          d="M11.6666 15.5C11.8876 15.5 12.0996 15.4122 12.2558 15.2559C12.4121 15.0996 12.4999 14.8877 12.4999 14.6666V9.66665C12.4999 9.44563 12.4121 9.23367 12.2558 9.07739C12.0996 8.92111 11.8876 8.83331 11.6666 8.83331C11.4456 8.83331 11.2336 8.92111 11.0773 9.07739C10.921 9.23367 10.8333 9.44563 10.8333 9.66665V14.6666C10.8333 14.8877 10.921 15.0996 11.0773 15.2559C11.2336 15.4122 11.4456 15.5 11.6666 15.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_343_3827">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
