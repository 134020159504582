import { Box, Flex, Input, Text, Button as CButton } from '@chakra-ui/react';
import React, { ReactNode, RefObject } from 'react';
import { StepNumber } from '../common/StepNumber';
import SvgArrowRight from '../common/svg/SvgArrowRight';

interface Props {
  currentStep: number;
  boxRef: RefObject<HTMLDivElement>;
}

export const Step3Box = ({ boxRef, currentStep }: Props) => {
  let component: ReactNode = (
    <Flex
      border="1px solid rgba(144, 159, 191, 0.1)"
      borderRadius={24}
      p={24}
      bg="white"
      alignItems="center"
    >
      <Box mr={8}>
        <StepNumber value={3} />
      </Box>
      <Flex alignItems="center" flex={1} justifyContent="space-between">
        <Text lineHeight="22px" fontWeight={600} color="lightGrey">
          Add Copy
        </Text>
        <Text color="lightGrey" fontWeight={400} lineHeight="22px">
          Optional
        </Text>
      </Flex>
    </Flex>
  );

  if (currentStep === 3) {
    component = (
      <>
        <Box
          border="1px solid rgba(144, 159, 191, 0.1)"
          borderRadius={24}
          p={24}
          bg="white"
        >
          <Text lineHeight="22px" fontWeight={600} color="purple">
            Add Copy
          </Text>

          <Text fontWeight={400} lineHeight={1.5} mt={4} color="lightGrey">
            Type a quick sentence or phrase in the bar
          </Text>
        </Box>

        <Box
          mt={24}
          border="1px solid rgba(144, 159, 191, 0.2)"
          borderRadius={24}
          p={24}
          bg="white"
        >
          <Input
            _placeholder={{
              color: 'lightGrey',
            }}
            lineHeight="22px"
            height={60}
            borderRadius={16}
            px={16}
            focusBorderColor="purple"
            placeholder="Type text"
          />

          <Box height={1} my={20} width="100%" bg="#8084941A" />

          <CButton bg="transparent">
            <Flex alignItems="center">
              <SvgArrowRight color="lightGrey" mr={8} />
              <Text fontWeight={400} lineHeight={1.5} color="lightGrey">
                Add text to canvas
              </Text>
            </Flex>
          </CButton>
        </Box>
      </>
    );
  }

  return <Box ref={boxRef}>{component}</Box>;
};
