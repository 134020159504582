import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HomeScreen from './home/HomeScreen';
import NotFoundScreen from './404/NotFoundScreen';
import Layout from './layout/Layout';

const Router = () => {
  return (
    <BrowserRouter key="switch">
      <Routes>
        {/* -- Routes using the Layout -- */}
        <Route element={<Layout />}>
          <Route path="/" element={<HomeScreen />} key="HomeScreen" />,
          <Route path="*" element={<NotFoundScreen />} key="Notfound404" />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
