import React from 'react';
import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: Biennale;
        src: local('Biennale'), url('./fonts/Biennale/Biennale-Regular.woff') format('woff');
        font-weight: 400;
        font-display: swap;
        font-style: normal;
      }
      
      @font-face {
        font-family: Biennale;
        src: local('Biennale'), url('./fonts/Biennale/Biennale-SemiBold.woff') format('woff');
        font-weight: 600;
        font-display: swap;
        font-style: normal;
      }
      
      @font-face {
        font-family: Biennale;
        src: local('Biennale'), url('./fonts/Biennale/Biennale-Bold.woff') format('woff');
        font-weight: 700;
        font-display: swap;
        font-style: normal;
      }
      `}
  />
);

export default Fonts;
