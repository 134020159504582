import { Box, Flex, Progress, SimpleGrid, Text } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import SvgPicture from '../common/svg/SvgPicture';
import { ENHANCEMENTS, keyOfEnhancement } from './EnhancementsSelection';
import Lottie from 'lottie-react';
import animationJson from '../common/lotties/loading.json';

interface Props {
  term: string;
  enhanceOn?: boolean;
  enhancements: keyOfEnhancement[];
  state: 'generating' | 'idle' | 'completed' | 'finished';
  generateProgress: number;
  currentStep: number;
}

const IdleSection = ({
  isVisible,
  enhanceOn,
  enhancements,
  term,
}: {
  isVisible: boolean;
  enhancements: keyOfEnhancement[];
  enhanceOn?: boolean;
  term: string;
}) => {
  const text =
    term.length > 0
      ? term
      : 'A summary of your ad background including any selected enhancements will be displayed here.';

  if (!isVisible) {
    return null;
  }

  return (
    <motion.div
      key="idle"
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ y: 10, duration: 0.2 }}
    >
      <Text
        color="darkGrey"
        fontWeight={600}
        fontSize={24}
        lineHeight="33.24px"
        textAlign="center"
      >
        {enhanceOn
          ? 'Enhance Your Background Image'
          : 'Build Your Ad Background'}
      </Text>

      <AnimatePresence>
        {enhanceOn && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box px={{ base: '10%', xl: '20%' }}>
              <Text
                mt={8}
                textAlign="center"
                fontSize={14}
                lineHeight="20px"
                color="lightGrey"
              >
                Additional details can be provided about the specific background
                image that you have in mind.
              </Text>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>

      <Box mt={47}>
        {enhancements.length > 0 ? (
          <Flex alignItems="center" justifyContent="center">
            <SimpleGrid
              columns={enhancements.length < 3 ? enhancements.length : 3}
              spacing={enhancements.length < 3 ? 24 : 16}
            >
              <AnimatePresence>
                {enhancements.map((e) => (
                  <motion.div
                    key={e}
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <Box
                      height={153}
                      width={153}
                      borderRadius={48}
                      overflow="hidden"
                    >
                      <img
                        src={ENHANCEMENTS[e].img}
                        height="100%"
                        width="100%"
                      />
                    </Box>
                  </motion.div>
                ))}
              </AnimatePresence>
            </SimpleGrid>
          </Flex>
        ) : (
          <Flex alignItems="center" justifyContent="center">
            <Flex
              height={153}
              width={153}
              borderRadius={48}
              bg="#F6F8FA"
              alignItems="center"
              justifyContent="center"
            >
              <SvgPicture color="lightGrey" />
            </Flex>
          </Flex>
        )}
      </Box>

      {/* we don't want to flick when text change from 2 line to 1 */}
      <Box minHeight={80} px={{ base: '10%', xl: '20%' }}>
        <Text
          mt={24}
          color="ligthGrey"
          fontWeight={400}
          lineHeight="22px"
          textAlign="center"
        >
          {text}
        </Text>
      </Box>
    </motion.div>
  );
};

const CompletedSection = ({ isVisible }: { isVisible: boolean }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <motion.div
      key="completed"
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ y: 10, duration: 0.2 }}
    >
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        <Flex
          height={221}
          width={221}
          borderRadius={48}
          bg="#F6F8FA"
          alignItems="center"
          justifyContent="center"
        />

        <Text
          mt={16}
          fontWeight={600}
          fontSize={24}
          lineHeight="33px"
          color="darkGrey"
        >
          Currently Selected
        </Text>

        <SimpleGrid columns={4} spacing={16} mt={54}>
          <Flex
            height={135}
            width={135}
            borderRadius={48}
            bg="lighterGrey"
            alignItems="center"
            justifyContent="center"
          />
          <Flex
            height={135}
            width={135}
            borderRadius={48}
            bg="lighterGrey"
            alignItems="center"
            justifyContent="center"
          />
          <Flex
            height={135}
            width={135}
            borderRadius={48}
            bg="lighterGrey"
            alignItems="center"
            justifyContent="center"
          />
          <Flex
            height={135}
            width={135}
            borderRadius={48}
            bg="lighterGrey"
            alignItems="center"
            justifyContent="center"
          />
        </SimpleGrid>
      </Flex>
    </motion.div>
  );
};

const GeneratingSection = ({
  isVisible,
  generateProgress,
  enhancements,
}: {
  isVisible: boolean;
  generateProgress: number;
  enhancements: keyOfEnhancement[];
}) => {
  if (!isVisible) {
    return null;
  }
  return (
    <motion.div
      key="generating"
      initial={{ y: -10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ y: 10, duration: 0.2 }}
    >
      <Box px={{ base: '10%', xl: '20%' }}>
        <Flex alignItems="center" justifyContent="center">
          <Lottie animationData={animationJson} loop />
        </Flex>

        <Flex alignItems="center" justifyContent="center">
          <Progress
            value={generateProgress}
            width={196}
            borderRadius={2}
            height={4}
            isAnimated
          />
        </Flex>

        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          mt={48}
        >
          <SimpleGrid
            columns={enhancements.length < 3 ? enhancements.length : 3}
            spacing={8}
          >
            {enhancements.map((e) => (
              <Box
                key={e}
                height={48}
                width={48}
                borderRadius={16}
                overflow="hidden"
              >
                <img src={ENHANCEMENTS[e].img} height="100%" width="100%" />
              </Box>
            ))}
          </SimpleGrid>

          <Box mt={24}>
            <Text color="lightGrey" fontWeight={400}>
              A floating sphere in a retro style with a gradient overlay
            </Text>
          </Box>
        </Flex>
      </Box>
    </motion.div>
  );
};

export const BackgroundImg = ({
  term,
  enhanceOn,
  enhancements,
  state,
  generateProgress,
  currentStep,
}: Props) => {
  if (currentStep !== 1) {
    return (
      <Flex
        height="100%"
        maxHeight={686}
        width="100%"
        border="1px solid rgba(144, 159, 191, 0.2)"
        borderRadius={24}
        bg="white"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        py={24}
      />
    );
  }

  return (
    <Flex
      height="100%"
      maxHeight={686}
      width="100%"
      border="1px solid rgba(144, 159, 191, 0.2)"
      borderRadius={24}
      bg="white"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      py={24}
    >
      <AnimatePresence>
        <IdleSection
          isVisible={state === 'idle'}
          enhancements={enhancements}
          enhanceOn={enhanceOn}
          term={term}
        />

        <GeneratingSection
          isVisible={state === 'generating'}
          enhancements={enhancements}
          generateProgress={generateProgress}
        />

        <CompletedSection isVisible={state === 'completed'} />
      </AnimatePresence>
    </Flex>
  );
};
