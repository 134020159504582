import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export default function SvgPicture(props: IconProps) {
  return (
    <Icon width="25" height="24" viewBox="0 0 25 24" {...props}>
      <g opacity="0.6" clipPath="url(#clip0_139_4445)">
        <path
          d="M11.6221 12.5361C11.3435 12.2573 11.0127 12.0362 10.6486 11.8853C10.2845 11.7344 9.89421 11.6567 9.50009 11.6567C9.10596 11.6567 8.71569 11.7344 8.35159 11.8853C7.98749 12.0362 7.65669 12.2573 7.37809 12.5361L0.538086 19.3761C0.630547 20.6302 1.19299 21.8032 2.11296 22.6605C3.03292 23.5178 4.2426 23.9962 5.50009 24.0001H19.5001C20.4798 23.9999 21.4376 23.7101 22.2531 23.1671L11.6221 12.5361Z"
          fill="currentColor"
        />
        <path
          d="M18.5 8C19.6046 8 20.5 7.10457 20.5 6C20.5 4.89543 19.6046 4 18.5 4C17.3954 4 16.5 4.89543 16.5 6C16.5 7.10457 17.3954 8 18.5 8Z"
          fill="currentColor"
        />
        <path
          d="M19.5 0H5.5C4.1744 0.00158786 2.90356 0.528882 1.96622 1.46622C1.02888 2.40356 0.501588 3.6744 0.5 5L0.5 16.586L5.964 11.122C6.42831 10.6576 6.97956 10.2892 7.58628 10.0378C8.19299 9.78644 8.84328 9.65707 9.5 9.65707C10.1567 9.65707 10.807 9.78644 11.4137 10.0378C12.0204 10.2892 12.5717 10.6576 13.036 11.122L23.667 21.753C24.21 20.9375 24.4998 19.9797 24.5 19V5C24.4984 3.6744 23.9711 2.40356 23.0338 1.46622C22.0964 0.528882 20.8256 0.00158786 19.5 0V0ZM18.5 10C17.7089 10 16.9355 9.7654 16.2777 9.32588C15.6199 8.88635 15.1072 8.26164 14.8045 7.53073C14.5017 6.79983 14.4225 5.99556 14.5769 5.21964C14.7312 4.44371 15.1122 3.73098 15.6716 3.17157C16.231 2.61216 16.9437 2.2312 17.7196 2.07686C18.4956 1.92252 19.2998 2.00173 20.0307 2.30448C20.7616 2.60723 21.3864 3.11992 21.8259 3.77772C22.2654 4.43552 22.5 5.20887 22.5 6C22.5 7.06087 22.0786 8.07828 21.3284 8.82843C20.5783 9.57857 19.5609 10 18.5 10Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_139_4445">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
