import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

interface Props {
  currentInfo: 1 | 2;
}

export const InfoBox = ({ currentInfo }: Props) => {
  let title = 'Build Your Background';
  let summary =
    'Type a quick sentence or phrase in the bar below summarizing the background of the ad that you would like to create.';

  if (currentInfo === 2) {
    title = 'Enhance Your Background Image';
    summary =
      'Specify image style, color, artist style, lighting, view, and/or detail level to ensure your background meets your vision.';
  }

  return (
    <Box
      mt={21}
      border="1px solid rgba(144, 159, 191, 0.1)"
      borderRadius={24}
      p={24}
      bg="white"
    >
      <motion.div
        layout
        key={currentInfo}
        // initial={{ y: 10, opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        // animate={{ y: 0, opacity: 1 }}
        exit={{ opacity: 0 }}
        // exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Text color="purple" fontWeight={600} lineHeight="22px">
            {title}
          </Text>
          <Text lineHeight="22px" fontWeight={400} color="lightGrey">
            {currentInfo} of 2
          </Text>
        </Flex>
        <Text mt={4} lineHeight={1.5} fontWeight={400} color="lightGrey">
          {summary}
        </Text>
      </motion.div>
    </Box>
  );
};
