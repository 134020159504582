import {
  AspectRatio,
  Box,
  Flex,
  SimpleGrid,
  Text,
  Button as CButton,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React, { RefObject, useState } from 'react';
import Button from '../common/Button';
import { StepNumber } from '../common/StepNumber';
import SvgUpload from '../common/svg/SvgUpload';

interface Props {
  currentStep: number;
  boxRef: RefObject<HTMLDivElement>;
  onSavePress: () => void;
}

export const Step2Box = ({ currentStep, boxRef, onSavePress }: Props) => {
  const [uploadPressed, setUploadPressed] = useState(false);
  const [selected, setSelected] = useState<null | number>(null);

  return (
    <Box ref={boxRef}>
      {currentStep === 1 ? (
        <Flex
          border="1px solid rgba(144, 159, 191, 0.1)"
          borderRadius={24}
          p={24}
          bg="white"
          alignItems="center"
        >
          <Box mr={8}>
            <StepNumber value={2} />
          </Box>
          <Text lineHeight="22px" fontWeight={600} color="lightGrey">
            Add Your Product & Logo
          </Text>
        </Flex>
      ) : currentStep === 2 ? (
        <>
          <Box
            border="1px solid rgba(144, 159, 191, 0.1)"
            borderRadius={24}
            p={24}
            bg="white"
          >
            <Text lineHeight="22px" fontWeight={600} color="purple">
              Add Your Product & Logo
            </Text>

            <Text fontWeight={400} lineHeight={1.5} mt={4} color="lightGrey">
              Upload .PNG or .JPEG files for your product and logo and add them
              to your background.
            </Text>
          </Box>
          <Box
            mt={24}
            border="1px solid rgba(144, 159, 191, 0.2)"
            borderRadius={24}
            p={24}
            bg="white"
          >
            {uploadPressed ? (
              <SimpleGrid columns={3} spacing={16}>
                {Array.from({ length: 6 }).map((_, i) => (
                  <AspectRatio key={i} ratio={1} width="100%">
                    <Box
                      cursor="pointer"
                      onClick={() => setSelected(i)}
                      bg="#909FBF33"
                      height="100%"
                      width="100%"
                      borderRadius={20}
                      border="1px solid rgba(144, 159, 191, 0.2)"
                    />
                  </AspectRatio>
                ))}
              </SimpleGrid>
            ) : (
              <>
                <Text lineHeight="22px" fontWeight={600} color="darkGrey">
                  Your Uploads
                </Text>

                <Text
                  fontWeight={400}
                  lineHeight="20px"
                  mt={8}
                  color="lightGrey"
                >
                  Image files uploaded will be temporarily saved until the end
                  of your session.
                </Text>
              </>
            )}

            <motion.div
              layout
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <Box mt={24}>
                {selected === null ? (
                  <Button onClick={() => setUploadPressed(true)}>
                    Upload from Computer
                  </Button>
                ) : (
                  <Flex>
                    <CButton
                      role="group"
                      borderColor="#909FBF66"
                      borderWidth={1}
                      borderRadius={16}
                      height={52}
                      width={72}
                      mr={10}
                      bg="white"
                      _hover={{
                        bg: 'white',
                        borderColor: 'purple',
                      }}
                    >
                      <SvgUpload
                        _groupHover={{ color: 'purple' }}
                        color="blue"
                      />
                    </CButton>

                    <Flex flex={1}>
                      <Button onClick={onSavePress}>Save and continue</Button>
                    </Flex>
                  </Flex>
                )}
              </Box>
            </motion.div>
          </Box>
        </>
      ) : (
        <Box
          bg="white"
          border="1px solid rgba(144, 159, 191, 0.1)"
          borderRadius={24}
          p={24}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text mr={8} color="darkGrey" fontWeight={600}>
              Product Assets
            </Text>
            <Text fontWeight={400} color="lightGrey" fontSize={14}>
              2 images
            </Text>
          </Flex>

          <Box height={1} my={20} width="100%" bg="#8084941A" />

          <Flex alignItems="center">
            <Box
              bg="lighterGrey"
              mr={12}
              height={48}
              width={48}
              borderRadius={16}
            />
            <Box
              bg="lighterGrey"
              mr={12}
              height={48}
              width={48}
              borderRadius={16}
            />
            <Flex flex={1}>
              <Box>
                <Text fontWeight={400} lineHeight={1.5} color="darkGrey">
                  Productimage.png,
                </Text>
                <Text fontWeight={400} lineHeight={1.5} color="darkGrey">
                  Companyslogan.png
                </Text>
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
