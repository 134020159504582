import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useIsMobile } from '../../hooks/useIsMobile';

export default function Nav() {
  const isMobile = useIsMobile();

  return (
    <Flex
      height={53}
      backgroundColor="white"
      py={16}
      px={20}
      borderRadius={24}
      backdropFilter="blur(14.5832px)"
      border="1px solid rgba(144, 159, 191, 0.15)"
      alignItems="center"
      justifyContent="space-between"
    >
      <Text
        fontFamily="biennale"
        color="darkGrey"
        fontSize={21}
        fontWeight={600}
      >
        Alvy.ai
      </Text>

      {isMobile && (
        <Text
          fontFamily="biennale"
          color="darkGrey"
          lineHeight={1.5}
          fontWeight={400}
        >
          Desktop only
        </Text>
      )}
    </Flex>
  );
}
