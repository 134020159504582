import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

export const StepDivider = () => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Box height={24} width={12} bg="#909FBF33" my={24} borderRadius={64} />
    </Flex>
  );
};
