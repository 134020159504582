import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { useUiStore } from '../../stores/ui';
import { useNavigationStore } from '../../stores/navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Flex } from '@chakra-ui/react';
import Nav from '../common/Nav';
import { useIsMobile } from '../../hooks/useIsMobile';

const Layout = observer(() => {
  const uiStore = useUiStore();
  const navigationStore = useNavigationStore();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  React.useEffect(() => {
    navigationStore.setNavigate(navigate);
  }, [navigate]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    uiStore.updateWindowSizes({
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
    });
    window.addEventListener('resize', () => {
      uiStore.updateWindowSizes({
        innerHeight: window.innerHeight,
        innerWidth: window.innerWidth,
      });
    });
  }, []);

  return (
    <Flex flex={1} overflowX="hidden" minHeight="100vh" width="100%">
      <Flex
        flexDirection="column"
        flex={1}
        width="100%"
        bg="#F5F7FB"
        minHeight="100vh"
      >
        <Container
          maxW={isMobile ? '100%' : '80%'}
          px={isMobile ? 20 : 0}
          pt={24}
        >
          <Nav />
          <Outlet />
        </Container>
      </Flex>
    </Flex>
  );
});

export default Layout;
